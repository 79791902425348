(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardHistory', {
        templateUrl: 'app/pages/domain/charts/history/history.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            historyData: '=',
        },
    });

    DashboardController.$inject = [
        '$filter',
        'ng2SessionService',
        '$timeout',
        'gettextCatalog',
        'ng2DateTimeService',
        'ng2DomainService',
        'Chart',
        'MON_MODULES',
        'ChangesHistoryService',
        'CHART_COLORS_CONSTANT',
        'ng2CoreDomainService',
        'ng2ActiveFeatureService',
        'ng2PropertiesSumService',
    ];
    /* @ngInject */
    function DashboardController (
        $filter,
        ng2SessionService,
        $timeout,
        gettextCatalog,
        ng2DateTimeService,
        ng2DomainService,
        Chart,
        MON_MODULES,
        ChangesHistoryService,
        CHART_COLORS_CONSTANT,
        ng2CoreDomainService,
        ng2ActiveFeatureService,
        ng2PropertiesSumService,
    ) {
        var vm = this;
        vm.historyCenterParameters = {};
        vm.$onInit = activate;
        vm.$onDestroy = destroyTimer;
        vm.onDemandScan = onDemandScan;
        vm.openScanDetails = openScanDetails;
        vm.getDomainCrawlStatus = getDomainCrawlStatus;

        function activate () {
            vm.loading = true;
            vm.historyCenterParameters = ChangesHistoryService.getHistoryCenterParameters('all');
            setupChart();
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.hasOnDemandScans = ng2ActiveFeatureService.isFeatureActivePure('on_demand_scans');
            vm.hasCrawlStatus = ng2CoreDomainService.hasCrawlStatus();
            vm.domain = ng2SessionService.domain;
            vm.domainGroup = ng2SessionService.domainGroup;
            vm.user = ng2SessionService.me;
            setupDomainHistory();
            setupSubHeaderText();
        }

        function setupDomainHistory () {
            var pageCount = [];
            var documentCount = [];
            var seoCount = [];
            var policiesCount = [];
            var qaCount = [];
            var accessibilityCount = [];
            var totalCrawled = [];
            vm.chartDataUnits = [];
            var showAcc = $filter('hasAccess')(vm.user, MON_MODULES.accessibility) && Boolean(vm.domain.features.accessibility);
            var showPolicy = $filter('hasAccess')(vm.user, MON_MODULES.policies);
            var showSEO = $filter('hasAccess')(vm.user, MON_MODULES.seo);
            var showQA = $filter('hasAccess')(vm.user, MON_MODULES.qa);
            vm.options.scales.xAxes[0].barPercentage = 0.1 * vm.historyData.length;
            vm.pagesCrawled = vm.historyData.length > 0 ? vm.historyData[0].page_count : 0;
            vm.documentsCrawled = vm.historyData.length > 0 ? vm.historyData[0].documents_count : 0;

            vm.historyData.reverse().forEach(function (scan) {
                vm.labels.push(ng2DateTimeService.format(scan.queued_at || scan.date, 'MMM DD'));
                pageCount.push(scan.page_count || 0);
                seoCount.push(scan.pages_with_seo_errors_count || 0);
                policiesCount.push(scan.pages_with_policy_matches_count || 0);
                qaCount.push(scan.pages_with_notifications_count || 0);
                accessibilityCount.push(scan.pages_with_accessibility_errors_count || 0);
                documentCount.push(scan.documents_count || 0);
                totalCrawled.push(ng2PropertiesSumService.sum(scan, ['documents_count', 'page_count']));
            });

            if (showPolicy || hasData(policiesCount)) {
                vm.series.push(gettextCatalog.getString('Policies'));
                vm.data.push(policiesCount);
                vm.chartDataUnits.push(gettextCatalog.getString('pages'));
                vm.colors.push(CHART_COLORS_CONSTANT.CERISE);
                vm.override.push({
                    label: gettextCatalog.getString('Policies'),
                    borderWidth: 1,
                    type: 'bar',
                    yAxisID: 'y-axis-0',
                    xAxisID: 'x-axis-0',
                    backgroundColor: CHART_COLORS_CONSTANT.CERISE,
                    borderColor: CHART_COLORS_CONSTANT.CERISE,
                    borderSkipped: ['top', 'bottom'],
                    pointRadius: 3,
                });
            }

            if (showQA || hasData(qaCount)) {
                vm.series.push(gettextCatalog.getString('QA'));
                vm.data.push(qaCount);
                vm.chartDataUnits.push(gettextCatalog.getString('pages'));
                vm.colors.push(CHART_COLORS_CONSTANT.LIGHT_BLUE);
                vm.override.push({
                    label: gettextCatalog.getString('QA'),
                    borderWidth: 1,
                    type: 'bar',
                    yAxisID: 'y-axis-0',
                    xAxisID: 'x-axis-0',
                    backgroundColor: CHART_COLORS_CONSTANT.LIGHT_BLUE,
                    borderColor: CHART_COLORS_CONSTANT.LIGHT_BLUE,
                    borderSkipped: ['top', 'bottom'],
                    pointRadius: 3,
                });
            }

            if (showAcc || hasData(accessibilityCount)) {
                vm.series.push(gettextCatalog.getString('Accessibility'));
                vm.data.push(accessibilityCount);
                vm.chartDataUnits.push(gettextCatalog.getString('pages'));
                vm.colors.push(CHART_COLORS_CONSTANT.LIGHT_PURPLE);
                vm.override.push({
                    label: 'Accessibility',
                    borderWidth: 1,
                    type: 'bar',
                    yAxisID: 'y-axis-0',
                    xAxisID: 'x-axis-0',
                    backgroundColor: CHART_COLORS_CONSTANT.LIGHT_PURPLE,
                    borderColor: CHART_COLORS_CONSTANT.LIGHT_PURPLE,
                    borderSkipped: ['top', 'bottom'],
                    pointRadius: 3,
                });
            }

            if (showSEO || hasData(seoCount)) {
                vm.series.push(gettextCatalog.getString('SEO'));
                vm.data.push(seoCount);
                vm.chartDataUnits.push(gettextCatalog.getString('pages'));
                vm.colors.push(CHART_COLORS_CONSTANT.LIGHT_YELLOW);
                vm.override.push({
                    label: gettextCatalog.getString('SEO'),
                    borderWidth: 1,
                    type: 'bar',
                    yAxisID: 'y-axis-0',
                    xAxisID: 'x-axis-0',
                    backgroundColor: CHART_COLORS_CONSTANT.LIGHT_YELLOW,
                    borderColor: CHART_COLORS_CONSTANT.LIGHT_YELLOW,
                    borderSkipped: ['top', 'bottom'],
                    pointRadius: 3,
                });
            }

            vm.series.push(gettextCatalog.getString('Pages crawled'));
            vm.colors.push(CHART_COLORS_CONSTANT.TURQUOISE);
            vm.data.push(pageCount);
            vm.chartDataUnits.push(gettextCatalog.getString('pages'));
            vm.override.push({
                label: gettextCatalog.getString('Pages crawled'),
                borderWidth: 2,
                type: 'line',
                fill: false,
                yAxisID: 'y-axis-0',
                xAxisID: 'x-axis-0',
                backgroundColor: CHART_COLORS_CONSTANT.TURQUOISE,
                borderColor: CHART_COLORS_CONSTANT.TURQUOISE,
                borderSkipped: ['top', 'bottom'],
                pointRadius: 3,
            });

            if (vm.domain.scan.scan_documents || hasData(documentCount)) {
                vm.data.push(documentCount);
                vm.data.push(totalCrawled);
                vm.chartDataUnits.push(gettextCatalog.getString('documents'), gettextCatalog.getString('pages'));
                vm.series.push(gettextCatalog.getString('Documents crawled'), gettextCatalog.getString('Total crawled'));
                vm.colors.push(CHART_COLORS_CONSTANT.GREEN_BLUE, CHART_COLORS_CONSTANT.LIGHT_TURQUOISE);
                vm.override.push(
                    {
                        label: gettextCatalog.getString('Documents crawled'),
                        borderWidth: 2,
                        type: 'line',
                        fill: false,
                        yAxisID: 'y-axis-0',
                        xAxisID: 'x-axis-0',
                        borderColor: CHART_COLORS_CONSTANT.GREEN_BLUE,
                        backgroundColor: CHART_COLORS_CONSTANT.GREEN_BLUE,
                        borderSkipped: ['top', 'bottom'],
                        pointRadius: 3,
                    },
                    {
                        label: gettextCatalog.getString('Total crawled'),
                        borderWidth: 2,
                        type: 'line',
                        fill: false,
                        yAxisID: 'y-axis-0',
                        xAxisID: 'x-axis-0',
                        borderColor: CHART_COLORS_CONSTANT.LIGHT_TURQUOISE,
                        backgroundColor: CHART_COLORS_CONSTANT.LIGHT_TURQUOISE,
                        borderSkipped: ['top', 'bottom'],
                        pointRadius: 3,
                    },
                );
            }

            vm.loading = false;
        }

        function hasData (arr) {
            var sum =
                Array.isArray(arr) &&
                arr.reduce(function (acc, curr) {
                    if (!isNaN(curr)) {
                        acc += curr;
                    }
                    return acc;
                }, 0);
            return !isNaN(sum) && sum > 0;
        }

        function destroyTimer () {
            if (typeof vm.timerPromise === 'object') {
                $timeout.cancel(vm.timerPromise);
            }
            vm.timerPromise = null;
        }

        function setupChart () {
            vm.series = [];
            vm.labels = [];
            vm.data = [];
            vm.colors = [];
            vm.override = [];

            vm.options = {
                tooltips: {
                    mode: 'label',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                data.datasets[tooltipItem.datasetIndex].label +
                                ': ' +
                                $filter('monNumber')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                            );
                        },
                    },
                },
                scales: {
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            barPercentage: 1,
                            categoryPercentage: 0.5,
                            type: 'category',
                            gridLines: {
                                offsetGridLines: true,
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                                maxTicksLimit: 4,
                                callback: function (value) {
                                    return $filter('monNumber')(value, '0,0a').toUpperCase();
                                },
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 13,
                    },
                },
            };
            vm.chart = new Chart(vm.options);
            vm.chart.setTooltipCustom({
                mode: 'label',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem, data) {
                        return (
                            data.datasets[tooltipItem.datasetIndex].label +
                            ': ' +
                            $filter('numeraljs', '0,0')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) +
                            ' ' +
                            vm.chartDataUnits[tooltipItem.datasetIndex]
                        );
                    },
                },
            });
            vm.chart.setScalesSettings({
                xAxes: [
                    {
                        id: 'x-axis-0',
                        barPercentage: 1,
                        categoryPercentage: 0.5,
                        type: 'category',
                        gridLines: {
                            offsetGridLines: true,
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        id: 'y-axis-0',
                        ticks: {
                            min: 0,
                            beginAtZero: true,
                            maxTicksLimit: 4,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0a').toUpperCase();
                            },
                        },
                    },
                ],
            });
        }

        function setupSubHeaderText () {
            vm.subHeaderText =
                gettextCatalog.getString('Last scan') +
                ' ' +
                $filter('monNumber')(vm.pagesCrawled) +
                ' ' +
                $filter('translateWithPlural')('page', vm.pagesCrawled, 'pages');
            if (vm.domain.scan.scan_documents) {
                vm.subHeaderText +=
                    ' ' +
                    gettextCatalog.getString('and') +
                    ' ' +
                    $filter('monNumber')(vm.documentsCrawled) +
                    ' ' +
                    $filter('translateWithPlural')('doc', vm.documentsCrawled, 'docs');
            }
        }

        function onDemandScan () {
            var domainId = vm.domain.id || ng2SessionService.getDomainId();
            ng2DomainService.rescan(domainId).then(function () {
                if (ng2SessionService.domain !== null && domainId === ng2SessionService.domain.id) {
                    ng2SessionService.domain.crawl_status = {};
                    ng2SessionService.domain.crawl_status.progress = 0;
                    vm.domain = ng2SessionService.domain;
                }
                vm.hasCrawlStatus = ng2CoreDomainService.hasCrawlStatus();
            }, angular.noop);
        }

        function openScanDetails () {
            ng2CoreDomainService.openScanDetails();
        }

        function getDomainCrawlStatus () {
            return ng2CoreDomainService.getDomainCrawlStatus();
        }

    }
})();
