/* eslint-disable no-console */
import { ErrorHandler, Inject, Injectable, InjectionToken, isDevMode } from '@angular/core';
import { ROLLBAR_CONFIG } from '@monsido/core/constants/rollbar-config.constant';
import { MonLogService } from '@monsido/services/mon-log-service/mon-log.service';
import { Rejection } from '@uirouter/core';
import Rollbar from 'rollbar';


export const RollbarService = new InjectionToken<Rollbar>('rollbar');

function isLocalDevMode (): boolean {
    return window.location.hostname === 'localhost' || isDevMode();
}

export function rollbarFactory (): Rollbar {
    return new Rollbar(ROLLBAR_CONFIG);
}

@Injectable()
export class RollbarErrorHandlerService implements ErrorHandler {
    constructor (@Inject(RollbarService) private rollbar: Rollbar, private monLogService: MonLogService) {}

    public handleError (err: string | Record<string, string>): void {
        if (err instanceof Rejection && err.type === 2) {
            if (isLocalDevMode()) {
                this.monLogService.error(err as string);
            }
            return;
        }

        if (isLocalDevMode()) {
            this.monLogService.error(err as string);
        } else {
            this.rollbar.error((err as Record<string, string>).originalError || err);
        }
    }

    public log (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): void {
        if (isLocalDevMode()) {
            this.monLogService.log(message);
        } else {
            this.rollbar.log(message, error, data, callback);
        }
    }

    public info (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): void {
        if (isLocalDevMode()) {
            this.monLogService.info(message);
        } else {
            this.rollbar.info(message, error, data, callback);
        }
    }

    public warn (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): void {
        if (isLocalDevMode()) {
            this.monLogService.warn(message);
        } else {
            this.rollbar.warn(message, error, data, callback);
        }
    }

    public error (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): void {
        if (isLocalDevMode()) {
            throw new Error(message);
        } else {
            this.rollbar.error(message, error, data, callback);
        }
    }

    public critical (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): void {
        if (isLocalDevMode()) {
            throw new Error(message);
        } else {
            this.rollbar.critical(message, error, data, callback);
        }
    }
}
