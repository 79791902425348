import { Injectable } from '@angular/core';

type PropertiesSumObjectInput = {
    [key: string]: unknown
}

@Injectable({
    providedIn: 'root',
})
export class PropertiesSumService {
    constructor () {}

    /**
     * Sums the object's properties according to the provided property array.
     */
    sum (object: PropertiesSumObjectInput, properties: string[]): number {
        object = object || {};
        properties = properties || [];

        return properties.reduce(
            (result: number, property: string) => {
                const value = object[property];
                const inc = typeof value === 'number' ? value : 0;
                return result + inc;
            },
            0,
        );
    }
}
