import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardPolicies', {
        templateUrl: 'app/pages/domain/charts/policies/policies.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            historyData: '<',
            totalContentCount: '<',
            linksCount: '<',
        },
    });

    DashboardController.$inject = [
        '$filter',
        'ng2PolicyRepoService',
        'complianceService',
        '$q',
        'ng2MonEventsService',
        'gettextCatalog',
        'ng2SessionService',
        'ChangesHistoryService',
        'FORM_CONSTANT',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function DashboardController (
        $filter,
        ng2PolicyRepoService,
        complianceService,
        $q,
        ng2MonEventsService,
        gettextCatalog,
        ng2SessionService,
        ChangesHistoryService,
        FORM_CONSTANT,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.historyCenterParameters = {};
        vm.$onInit = activate;
        vm.hasPolicies = hasPolicies;
        vm.openPolicy = openPolicy;

        function activate () {
            vm.policyCategory = {};
            vm.pagesErrorsCount = 0;
            vm.policies = [];
            vm.historyCenterParameters = ChangesHistoryService.getHistoryCenterParameters('policy');
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.labels = [[''], ['']];
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            vm.domain = ng2SessionService.domain;
            getPolicies();
        }

        function getPolicies () {
            var params = {
                page_size: 0,
            };

            var promises = [getPolicyPages(), ng2PolicyRepoService.domainGetAll(params)];
            vm.loading = true;

            $q.all(promises).then(function ($values) {
                vm.pagesErrorsCount = parseInt($values[0].total);
                vm.policies = $values[1];
                setupPolicyCounts($values[1]);
                setupDoughnutChart($values[1]);
                vm.loading = false;
            }, angular.noop);
        }

        function getPolicyPages () {
            var params = {
                page_size: 1,
                page: 1,
            };
            return ng2PolicyRepoService.domainGetPages(params);
        }

        function setupPolicyCounts (policies) {
            vm.violationCount = policies.reduce(function (num, policy) {
                if (policy.matches_count > 0 && policy.mode === 'violation') {
                    num++;
                }
                return num;
            }, 0);

            vm.requiredCount = policies.reduce(function (num, policy) {
                if (policy.matches_count > 0 && policy.mode === 'required') {
                    num++;
                }
                return num;
            }, 0);

            vm.searchCount = policies.reduce(function (num, policy) {
                if (policy.matches_count > 0 && policy.mode === 'search') {
                    num++;
                }
                return num;
            }, 0);

            vm.policiesWithErrors = vm.violationCount + vm.requiredCount + vm.searchCount;
        }

        function setupDoughnutChart (policies) {
            var activePolicies = policies.filter(function (policy) {
                return policy.state !== 'running';
            });

            vm.data = complianceService.policyCompliance(
                { page_count: vm.totalContentCount, documents_count: 0 },
                activePolicies,
                vm.pagesErrorsCount,
                vm.totalContentCount,
                vm.linksCount,
            );
            vm.options = {
                centerText: {
                    percentageLabel: $filter('monNumber')(
                        complianceService.policyCompliancePercentage(
                            { page_count: vm.totalContentCount, documents_count: 0 },
                            activePolicies,
                            vm.pagesErrorsCount,
                            vm.totalContentCount,
                            vm.linksCount,
                        ) / 100,
                        '0.[00] %',
                    ),
                    totalLabel: gettextCatalog.getString('overall compliance'),
                    subLabel: gettextCatalog.getString('achieved'),
                    totalFontSize: 150,
                    subFontSize: 150,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: false,
                },
                hover: {
                    mode: null,
                },
            };
        }

        function openPolicy () {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
            };
            const callback = function (policy) {
                if (angular.isObject(policy)) {
                    getPolicies();
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function hasPolicies () {
            return vm.policies.length > 0;
        }

    }
})();
