import { ChartFactory } from './base-chart/chart-factory';
import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { ChartsDataHelper } from '../../helpers/charts/charts-data-helper';

(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name QALineChartService
     * @description
     * Create the necessary objects to shape the QA line chart
     */
    angular.module('blocks.service').factory('QALineChartService', QALineChartService);

    QALineChartService.$inject = ['gettextCatalog'];

    function QALineChartService (gettextCatalog) {
        let qaLineChartService;
        function init () {
            qaLineChartService = new ChartFactory(new LineChart());
            qaLineChartService.setOptions([gettextCatalog.getString('Issues'), gettextCatalog.getString('Content')]);
            qaLineChartService.setColors(['#da5c5b', '#b960e2', '#ea9a5c', '#ff9d4e', '#a9dad6']);
            qaLineChartService.setOverrides([3, 2]);
            qaLineChartService.setSeries([
                gettextCatalog.getString('Broken links'),
                gettextCatalog.getString('Broken images'),
                gettextCatalog.getString('Misspellings'),
                gettextCatalog.getString('Content with issues'),
                gettextCatalog.getString('Scanned content'),
            ]);
            qaLineChartService.setData([
                'dead_links_count',
                'dead_images_count',
                'spelling_errors_confirmed_count',
                'pages_with_notifications_count',
                ['documents_count', 'page_count'],
            ],
            new ChartsDataHelper('time'));
            return qaLineChartService;
        }
        return init;
    }
})();
