import { Injectable } from '@angular/core';
import { PageFixAccessibilityConfigService } from '../page-fix-accessibility-config/page-fix-accessibility-config.service';
import { EditAltTextService } from '../form/edit-alt-text/edit-alt-text.service';
import { ActiveFeatureService } from '@monsido/ng2/services/active-feature/active-feature.service';
import { PageFixRepoService } from '@client/app/services/api/pagefix-repo/page-fix-repo.service';
import { PAGE_FIX_FIXABLE_TYPES } from '../../constants/page-fix-fixable-types';
import PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG from '@client/app/modules/page-fix/constants/page-fix-accessibility-check-config.json';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { PageFixModel } from '@client/app/services/api/pagefix-repo/page-fix-model';
import { IssueInterface } from '@client/app/modules/accessibility/interfaces/issue-interface';
import { AccessibilityFastTrackModel } from '@client/app/models/accessibility/fast-track.model';

type PageFixIssueType = IssueInterface & { page_fix_fixable: boolean, page_fixed: boolean, html_snippet: string };

@Injectable({
    providedIn: 'root',
})
export class AccessibilityAutofixService {
    constructor (
        private pageFixRepoService: PageFixRepoService,
        private activeFeatureService: ActiveFeatureService,
        private editAltTextService: EditAltTextService,
        private pageFixAccessibilityConfigService: PageFixAccessibilityConfigService,
    ) {}

    parseChecks (checks: PageFixIssueType[], sourceCode: AccessibilityFastTrackModel): Promise<void | unknown[]> {
        if (!this.activeFeatureService.isFeatureActive('page_fix')) {
            return Promise.resolve(checks);
        }

        const accSourceCodePromise = this.getPageFixes(PAGE_FIX_FIXABLE_TYPES.accessibility_source_code, sourceCode.id);
        const accChecksPromise = this.getPageFixes(PAGE_FIX_FIXABLE_TYPES.accessibility_check);

        return Promise.all([accSourceCodePromise, accChecksPromise]).then(([accSourceCode, accChecks]) => {
            const fixes = [...accSourceCode, ...accChecks];
            this.editAltTextService.setHTMLSnippet(sourceCode.source_code);
            for (let i = 0; i < checks.length; i++) {
                checks[i].html_snippet = sourceCode.source_code;
                checks[i].page_fix_fixable = this.isCheckFixable(checks[i]);
                checks[i].page_fixed = checks[i].page_fix_fixable && this.anyFixesForCheck(fixes, checks[i]);
            }
            return checks;
        }, () => {});
    }

    useCheckId (checkId: string): boolean {
        const checkConfig = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[checkId];
        return Boolean(
            checkConfig && PAGE_FIX_FIXABLE_TYPES[checkConfig.type] === PAGE_FIX_FIXABLE_TYPES.accessibility_check,
        );
    }

    isCheckFixed (fix: PageFixModel, check: PageFixIssueType): boolean {
        if (fix.change.check_id === check.id) {
            return true;
        } else if (this.isAltImageCheck(check)) {
            return this.pageFixAccessibilityConfigService.isPageFixImg(fix);
        }
        return false;
    }

    getElementRegex (): RegExp {
        return /^((?![\W_\s])|(?![\-]{2})[a-zA-Z\-\d]){1,}$/;
    }

    isElementNameValid (elementName: string): boolean {
        if (!elementName) {
            return false;
        }
        const regex = this.getElementRegex();
        return regex.test(elementName);
    }

    getElementPreview (elementName: string): string {
        if (!this.isElementNameValid(elementName)) {
            return '';
        }
        try {
            const el = document.createElement(elementName);
            el.innerText = 'Text';
            el.className = 'inline-block my-0 mx-0 py-0 px-0';
            return el.outerHTML;
        } catch (e) {
            // Silently fail
        }
        return '';
    }

    private anyFixesForCheck (fixes: PageFixModel[], check: PageFixIssueType): boolean {
        return fixes.some((fix) => this.isCheckFixed(fix, check));
    }

    private isAltImageCheck (check: PageFixIssueType): boolean {
        return this.pageFixAccessibilityConfigService.isCheckImg(check);
    }

    private isCheckFixable (check: PageFixIssueType): boolean {
        if (PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[check.id]) {
            if (this.isAltImageCheck(check)) {
                if (this.editAltTextService.canReadSource()) {
                    return !this.editAltTextService.getSource().startsWith('data:image/');
                }
            }
            return true;
        }
        return false;
    }

    private getPageFixes (type: typeof PAGE_FIX_FIXABLE_TYPES[keyof typeof PAGE_FIX_FIXABLE_TYPES], id?: number): Promise<MonTableCollection<PageFixModel>> {
        return this.pageFixRepoService.getAll({
            fixable_type: type,
            fixable_id: id,
        }).catch(() => Promise.resolve([]));
    }
}
