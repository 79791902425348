(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceCategoryService
     * @description Service to get report category and translation details
     */
    angular
        .module('modules.page-performance.page-details')
        .service('PagePerformanceCategoryService', PagePerformanceCategoryService);

    PagePerformanceCategoryService.$inject = ['Lodash', 'ng2PagePerformancePageDetailsService'];
    /* @ngInject*/
    function PagePerformanceCategoryService (Lodash, ng2PagePerformancePageDetailsService) {
        return {
            getAuditsFromCategory: getAuditsFromCategory,
            getCategoryGroup: getCategoryGroup,
            getAuditReference: getAuditReference,
            getI18n: getI18n,
            getCategory: getCategory,
        };

        /**
         * @memberOf PagePerformanceCategoryService
         * @desc Format the audit item by it's heading type
         * @param {String} categoryName - Audit category
         */
        function getAuditsFromCategory (categoryName) {
            var report = getReport();
            var auditIds = Lodash.map(getCategory(categoryName).auditRefs, 'id');
            return Lodash.map(auditIds, function (id) {
                return Lodash.find(report.audits, { id: id });
            });
        }

        /**
         * @memberOf PagePerformanceCategoryService
         * @desc Get Audit Reference
         * @param {String} categoryName - Audit category
         * @param {String} auditId - Audit Id name
         */
        function getAuditReference (categoryName, auditId) {
            return Lodash.find(getCategory(categoryName).auditRefs, { id: auditId });
        }

        /**
         * @memberOf PagePerformanceCategoryService
         * @desc Get Audit Reference
         * @param {String} categoryGroupId - Audit category ID name
         */
        function getCategoryGroup (categoryGroupId) {
            var report = getReport();
            return report.categoryGroups[categoryGroupId];
        }

        /**
         * @memberOf PagePerformanceCategoryService
         * @desc Get all I18n translations available in the active report
         */
        function getI18n () {
            var report = getReport();
            return report.i18n;
        }

        /**
         * @memberOf PagePerformanceCategoryService
         * @desc get entire Category object of Report Audit
         */
        function getCategory (categoryName) {
            var report = getReport();
            return report.categories[categoryName];
        }

        // PROTECTED

        /**
         * @memberOf PagePerformanceCategoryService
         * @desc Get the entire provided Report of a Page
         */
        function getReport () {
            return ng2PagePerformancePageDetailsService.getReport();
        }
    }
})();
