(function () {
    'use strict';
    angular.module('modules.page_fix.components').component('pageFixIcon', {
        transclude: true,
        templateUrl: 'app/modules/page-fix/components/icon/page-fix-icon.html',
        controller: AutofixController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
            type: '@',
            subType: '@?',
            typeId: '@?',
            cb: '&?',
        },
    });

    AutofixController.$inject = [
        'PageFixService',
        'ng2PageFixRepoService',
        'ng2AccessibilityAutofixService',
        'PAGE_FIX_FIXABLE_TYPES',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function AutofixController (
        PageFixService,
        ng2PageFixRepoService,
        ng2AccessibilityAutofixService,
        PAGE_FIX_FIXABLE_TYPES,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.add = add;
        vm.remove = remove;
        vm.update = update;
        vm.openPopup = openPopup;
        vm.isFixed = isFixed;

        function activate () {
            vm.showPageFix = true;
            if (vm.type === PAGE_FIX_FIXABLE_TYPES.accessibility_source_code) {
                vm.showPageFix = ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_source_code');
            } else if (vm.type === PAGE_FIX_FIXABLE_TYPES.accessibility_check) {
                vm.showPageFix = ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_check');
            }
        }

        function openPopup () {
            vm.fixableTypes = PAGE_FIX_FIXABLE_TYPES;
            vm.data = null;
            if (vm.notesPopupOpen === undefined || vm.notesPopupOpen === false) {
                ng2PageFixRepoService.getAll({ fixable_type: vm.type, fixable_id: vm.typeId || vm.ngModel.id }).then(function (
                    pageFixes,
                ) {
                    if (
                        vm.type === PAGE_FIX_FIXABLE_TYPES.accessibility_source_code ||
                        vm.type === PAGE_FIX_FIXABLE_TYPES.accessibility_check
                    ) {
                        for (var i = 0; i < pageFixes.length; i++) {
                            if (ng2AccessibilityAutofixService.isCheckFixed(pageFixes[i], vm.ngModel)) {
                                vm.data = pageFixes[i];
                                break;
                            }
                        }
                    } else {
                        vm.data = pageFixes[0];
                    }
                },
                angular.noop);
            } else {
                vm.data = null;
            }
        }

        function add () {
            PageFixService.add(
                vm.type,
                vm.ngModel,
                vm.typeId,
                vm.subType,
                function (result) {
                    if (vm.type === PAGE_FIX_FIXABLE_TYPES.link && vm.ngModel.link) {
                        vm.ngModel.link.page_fixed = result !== undefined;
                    } else {
                        vm.ngModel.page_fixed = result !== undefined;
                    }
                    if (typeof vm.cb === 'function') {
                        vm.cb(vm.ngModel);
                    }
                },
                angular.noop,
            );
        }

        function remove () {
            PageFixService.remove(vm.data).then(function (result) {
                if (vm.type === PAGE_FIX_FIXABLE_TYPES.link && vm.ngModel.link) {
                    vm.ngModel.link.page_fixed = result !== undefined;
                } else {
                    vm.ngModel.page_fixed = result === undefined;
                }
            }, angular.noop);
        }

        function isFixed () {
            if (vm.type === PAGE_FIX_FIXABLE_TYPES.link && vm.ngModel.link) {
                return vm.ngModel.link.page_fixed;
            } else {
                return vm.ngModel.page_fixed;
            }
        }

        function update () {
            PageFixService.update(vm.type, vm.ngModel, vm.subType, vm.data);
        }
    }
})();
