import { ChartFactory } from './base-chart/chart-factory';
import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { ChartsDataHelper } from '../../helpers/charts/charts-data-helper';

(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name ReadabilityLineChartFactory
     * @description
     * Create the necessary objects to shape the readability line chart
     */

    angular.module('blocks.service').service('ReadabilityLineChartService', ReadabilityLineChartFactory);

    ReadabilityLineChartFactory.$inject = [
        'gettextCatalog',
        'QAReadabilityService',
    ];

    function ReadabilityLineChartFactory (
        gettextCatalog,
        QAReadabilityService,
    ) {
        let readabilityLineChartFactory;
        let readabilityTest;
        function init (domain) {
            setReadabilityTest(domain.features.readabilityTest);
            readabilityLineChartFactory = new ChartFactory(new LineChart());
            readabilityLineChartFactory.setOptions([gettextCatalog.getString('Issues'), gettextCatalog.getString('Content')]);
            readabilityLineChartFactory.setColors(QAReadabilityService.getColorPallete());
            readabilityLineChartFactory.setOverrides([QAReadabilityService.getReadabilityLevels(readabilityTest).length]);
            readabilityLineChartFactory.setSeries(QAReadabilityService.getReadabilityLevels(readabilityTest).map((level) => {
                return QAReadabilityService.getTranslationScore(level, readabilityTest);
            }));
            readabilityLineChartFactory.getData = getData;
            return readabilityLineChartFactory;
        }
        return init;

        function getData (crawls) {
            if (!crawls || crawls.length === 0) {
                return [];
            }
            var helper = new ChartsDataHelper('time');
            var readabilityLevels = QAReadabilityService.getReadabilityLevels(readabilityTest);

            return readabilityLevels.map(function (level) {
                return crawls.map(function (crawl) {
                    let ReadabilityDistributionScore = 0;
                    if (crawl.readability_distribution) {
                        ReadabilityDistributionScore = crawl.readability_distribution[level];
                    }
                    return helper.setData(
                        {
                            result: ReadabilityDistributionScore,
                            date: crawl.date,
                        },
                        'result',
                    );
                });
            });
        }

        function setReadabilityTest (test) {
            readabilityTest = test;
        }
    }
})();
