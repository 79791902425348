import { ChartModel } from '@client/app/blocks/service/charts/base-chart/chart-factory';
import { BaseChart } from '../chart';

export class LineChart extends BaseChart {
    series: string[] = [];

    constructor (input?: Partial<ChartModel>) {
        super(input);
        this.series = [];
    }

    addSerie (entry: string | string[]): void {
        if (Array.isArray(entry)) {
            this.addSeries(entry);
            return;
        }

        this.series.push(entry);
    }

    addSeries (series: string[]): void {
        if (!Array.isArray(series)) {
            throw new Error('Input must be an array');
        }
        this.series = this.series.concat(series);
    }

}
