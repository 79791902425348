import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.data_privacy.pages').component('privacyIssues', {
        templateUrl: 'app/modules/data-privacy/pages/issues/issues.html',
        controller: DataPrivacyIssuesController,
        controllerAs: 'vm',
    });

    DataPrivacyIssuesController.$inject = [
        'ng2DataPrivacyViolationRepoService',
        '$controller',
        'gettextCatalog',
        'LIKELIHOODS',
        'REGULATIONS',
        'INFOTYPE_GROUPS',
        'activeFeatures',
        'DataPrivacyService',
        '$deepmerge',
        'DataPrivacyEntitiesViolationService',
        'ng2MonEventsService',
        'ng2MonModalService',
    ];
    /* @ngInject */
    function DataPrivacyIssuesController (
        ng2DataPrivacyViolationRepoService,
        $controller,
        gettextCatalog,
        LIKELIHOODS,
        REGULATIONS,
        INFOTYPE_GROUPS,
        activeFeatures,
        DataPrivacyService,
        $deepmerge,
        DataPrivacyEntitiesViolationService,
        ng2MonEventsService,
        ng2MonModalService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.formatText = DataPrivacyService.formatText;
        vm.getLikelihood = getLikelihood;
        vm.onFiltersChanges = onFiltersChanges;
        vm.goToPages = goToPages;
        vm.update = update;
        vm.openHelpCenter = openHelpCenter;
        vm.update = update;

        vm.exports = [
            {
                name: gettextCatalog.getString('Data issues export'),
                data: {
                    category: 'data_protection_violations',
                },
            },
        ];

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));

            vm.violations = [];
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    value: 'all',
                },
                {
                    name: gettextCatalog.getString('High'),
                    value: 'high',
                },
                {
                    name: gettextCatalog.getString('Medium'),
                    value: 'medium',
                },
                {
                    name: gettextCatalog.getString('Low'),
                    value: 'low',
                },
                {
                    name: gettextCatalog.getString('Ignored'),
                    value: 'ignored',
                },
                {
                    name: gettextCatalog.getString('Fixed'),
                    value: 'fixed',
                },
            ];
            vm.activeTab = vm.tabs[0].value;
            vm.currentSort = { by: 'identifier', direction: 'asc' };
            vm.filters = { 'Data Regulations': [], 'Issue type': [] };
            vm.availableFilters = {
                'Data Regulations': REGULATIONS,
                'Issue type': INFOTYPE_GROUPS.sort(function (a, b) {
                    if (a.value === 'other') {
                        return 1;
                    }
                    if (b.value === 'other') {
                        return -1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                }),
            };
            vm.showFilters = activeFeatures.table_filters;
            vm.isFiltersOpen = false;

            getPage();
        }

        function getPage () {
            var params = {
                search: vm.search,
                page: vm.page,
                page_size: vm.pageSize,
                sort_dir: vm.currentSort.direction,
                sort_by: vm.currentSort.by,
            };

            if (vm.activeTab !== 'fixed' && vm.activeTab !== 'ignored') {
                if (vm.activeTab !== 'all') {
                    params.priority = vm.activeTab;
                }
                params.status = 'active';
            } else {
                params.status = vm.activeTab;
            }

            params['regulations[]'] = vm.filters['Data Regulations'].map(function (regulation) {
                return regulation.value;
            });
            params['group[]'] = vm.filters['Issue type'].map(function (type) {
                return type.value;
            });

            vm.progress = ng2DataPrivacyViolationRepoService.getAll(null, params).then(function (violations) {
                vm.violations = violations;
                vm.pageCount = parseInt(vm.violations.total, 10);
                updateDropdowns();
            }, angular.noop);
        }

        function openHelpCenter (infoType) {
            var params = {
                size: 'lg',
                body: 'privacyDialogsHelpCenter',
                data: { infoType: infoType },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getLikelihood (violation) {
            if (LIKELIHOODS[violation.likelihood]) {
                return DataPrivacyService.getViolationLikelihood(violation);
            } else {
                return gettextCatalog.getString('Likelihood unspecified');
            }
        }

        function onFiltersChanges (changes) {
            vm.filters = changes;
            getPage();
        }

        /**
         * @param issue - a infotype or violation object
         * @param violation - boolean to determine if violation
         */
        function goToPages (issue, violation) {
            var params = {};
            if (!violation) {
                params = {
                    size: 'lg',
                    body: 'privacyViolationPages',
                    data: { infoType: issue, totalPagesCount: vm.pageCount },
                };
            } else {
                params = {
                    size: 'lg',
                    body: 'privacyViolationPages',
                    data: { violation: issue, totalPagesCount: vm.pageCount },
                };
            }

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function update (status, violation, confirmMessage) {
            ng2MonModalService.confirm(confirmMessage).then(()=>{
                const violationToUpdate = $deepmerge({}, violation);
                violationToUpdate.status = status;
                DataPrivacyEntitiesViolationService.update(violationToUpdate).then(function () {
                    getPage();
                }, angular.noop);
            });
        }
    }
})();
