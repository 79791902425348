import { PropertiesSumService } from '@client/app/filters/properties-sum.service';
import { ChartDataEntry } from '../../service/charts/base-chart/chart-factory';
import { CrawlHistory } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';

export class ChartsDataHelper {
    propertiesSumService: PropertiesSumService;

    constructor (private dataType: string) {
        this.propertiesSumService = new PropertiesSumService();
    }

    setData (crawl: CrawlHistory, params: string | string[]): ChartDataEntry {
        switch (this.dataType) {
            case 'time':
                return this.getDateAndCount(crawl, params);
            default:
                return this.getCount(crawl, params);
        }
    }

    getCount (crawl: CrawlHistory, params: string | string[]): number {
        if (typeof params === 'string') {
            params = [params];
        }
        return this.propertiesSumService.sum(crawl, params);
    }

    getDateAndCount (crawl: CrawlHistory, param: string | string[]): {x: string, y: number} {
        if (typeof param === 'string') {
            param = [param];
        }

        return {
            x: crawl.date,
            y: this.propertiesSumService.sum(crawl, param),
        };
    }
}
