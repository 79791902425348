(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceAuditImpactService
     * @description Confirms details of a Lighthouse Report Audit
     */
    angular
        .module('modules.page-performance')
        .service('PagePerformanceAuditImpactService', PagePerformanceAuditImpactService);

    PagePerformanceAuditImpactService.$inject = [
        'PAGE_PERFORMANCE_AUDIT_IMPACT',
        'ng2PagePerformancePageDetailsService',
        'Lodash',
        'PAGE_PERFORMANCE_CATEGORY',
        'gettextCatalog',
    ];

    /* @ngInject*/
    function PagePerformanceAuditImpactService (
        PAGE_PERFORMANCE_AUDIT_IMPACT,
        ng2PagePerformancePageDetailsService,
        Lodash,
        PAGE_PERFORMANCE_CATEGORY,
        gettextCatalog,
    ) {
        return {
            getImpactValue: getImpactValue,
            getImpactClass: getImpactClass,
            getImpactTranslation: getImpactTranslation,
        };

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get impact value of active audit
         * @param {Object} audit - Audit Object
         * @return {Number}
         */
        function getImpactValue (audit) {
            return getAuditImpactValue(audit);
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get Audit Impact class names
         * @param {Object} audit - Audit Object
         * @return {String}
         */
        function getImpactClass (audit) {
            var impactValue = getAuditImpactValue(audit);
            if (impactValue === PAGE_PERFORMANCE_AUDIT_IMPACT.LOW) {
                return 'label-fixed label label-info';
            } else if (impactValue === PAGE_PERFORMANCE_AUDIT_IMPACT.MEDIUM) {
                return 'label-fixed label label-warning';
            }

            return 'label-fixed label label-danger';
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get impact translation
         * @param {Object} audit - Audit Object
         * @return {String}
         */
        function getImpactTranslation (audit) {
            var impactValue = getAuditImpactValue(audit);
            if (impactValue === PAGE_PERFORMANCE_AUDIT_IMPACT.LOW) {
                return gettextCatalog.getString('Low');
            } else if (impactValue === PAGE_PERFORMANCE_AUDIT_IMPACT.MEDIUM) {
                return gettextCatalog.getString('Medium');
            } else if (impactValue === PAGE_PERFORMANCE_AUDIT_IMPACT.HIGH) {
                return gettextCatalog.getString('High');
            }
        }

        // PROTECTED

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get the entire provided Report of a Page
         */
        function getReport () {
            return ng2PagePerformancePageDetailsService.getReport();
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @param {Object} audit - Audit Object
         * @desc Get the Category related to the active audit
         */
        function getCategory (audit) {
            var report = getReport();
            return Lodash.find(report.categories, function (category) {
                return Lodash.find(category.auditRefs, { id: audit.id }) !== undefined;
            });
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @param {Object} audit - Audit Object
         * @desc Get the Audit Impact ENUM value
         * @return {Number}
         */
        function getAuditImpactValue (audit) {
            var category = getCategory(audit);
            if (category.id === PAGE_PERFORMANCE_CATEGORY.PERFORMANCE) {
                return getPerformanceImpactValue(audit);
            } else if (category.id === PAGE_PERFORMANCE_CATEGORY.BEST_PRACTICE) {
                return getBestPracticeImpactValue();
            } else if (category.id === PAGE_PERFORMANCE_CATEGORY.SEO) {
                return getSEOImpactValue();
            } else if (category.id === PAGE_PERFORMANCE_CATEGORY.PWA) {
                return getPWAImpactValue(category, audit);
            }
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get the Audit Impact ENUM value from the Best Practice Category
         * @return {Number}
         */
        function getBestPracticeImpactValue () {
            return PAGE_PERFORMANCE_AUDIT_IMPACT.MEDIUM;
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get the Audit Impact ENUM value from the PWA Category
         * @return {Number}
         */
        function getPWAImpactValue (category, audit) {
            var auditRef = Lodash.find(category.auditRefs, { id: audit.id });
            if (auditRef.weight <= 3) {
                return PAGE_PERFORMANCE_AUDIT_IMPACT.LOW;
            }
            if (auditRef.weight <= 6) {
                return PAGE_PERFORMANCE_AUDIT_IMPACT.MEDIUM;
            }
            return PAGE_PERFORMANCE_AUDIT_IMPACT.HIGH;
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get the Audit Impact ENUM value from the SEO Category
         * @return {Number}
         */
        function getSEOImpactValue () {
            return PAGE_PERFORMANCE_AUDIT_IMPACT.MEDIUM;
        }

        /**
         * @memberOf PagePerformanceAuditImpactService
         * @desc Get the Audit Impact ENUM value from the Performance Category
         * @return {Number}
         */
        function getPerformanceImpactValue (audit) {
            var overallSavingsMs = audit.details && audit.details.overallSavingsMs;
            if (!overallSavingsMs || overallSavingsMs <= 50) {
                return PAGE_PERFORMANCE_AUDIT_IMPACT.LOW;
            }
            if (overallSavingsMs <= 250) {
                return PAGE_PERFORMANCE_AUDIT_IMPACT.MEDIUM;
            }
            return PAGE_PERFORMANCE_AUDIT_IMPACT.HIGH;
        }
    }
})();
