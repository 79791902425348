(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name dataPrivacyService
     * @description Service to handle comments
     */
    angular
        .module('services.entities')
        .factory('DataPrivacyEntitiesViolationService', DataPrivacyEntitiesViolationService);

    DataPrivacyEntitiesViolationService.$inject = ['ng2DataPrivacyViolationRepoService'];
    /* @ngInject*/
    function DataPrivacyEntitiesViolationService (ng2DataPrivacyViolationRepoService) {
        return {
            update: update,
        };

        function update (violation) {
            return ng2DataPrivacyViolationRepoService.update(violation);
        }
    }
})();
