import { LineChart } from '@client/app/modules/charts/models/charts/line';
(function () {
    'use strict';
    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name QASpellcheckLineChartService
     * @description
     * Create the necessary objects to shape the QA spellcehck line chart
     */

    angular.module('blocks.service').factory('QASpellcheckLineChartService', QASpellcheckLineChartService);

    QASpellcheckLineChartService.$inject = ['$filter', 'gettextCatalog', 'ng2DateTimeService','ng2LegendCursorService'];

    function QASpellcheckLineChartService ($filter, gettextCatalog, ng2DateTimeService, ng2LegendCursorService) {
        var vm = this;
        /**
         * @memberOf QASpellcheckLineChartService
         * @description Initialize the QASpellcheckLineChartService factory
         */
        function init () {
            vm.lineChartSettings = new LineChart();

            return {
                getOption: getOption,
                getColors: getColors,
                getSeries: getSeries,
                getOverride: getOverride,
                getLabels: getLabels,
                getData: getData,
            };
        }

        return init;

        /**
         * @memberOf QASpellcheckLineChartService
         * @description The option object for the QA spellcehck line chart
         */
        function getOption () {
            vm.lineChartSettings.setTooltipSettings({
                enabled: true,
                mode: 'x-axis',
                bodySpacing: 5,
            });
            vm.lineChartSettings.setLegendSettings(true, 'bottom');
            vm.lineChartSettings.setScalesSettings({
                xAxes: [
                    {
                        id: 'x-axis-0',
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: 4,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value);
                            },
                            min: 0,
                        },
                    },
                ],
            });
            vm.lineChartSettings.setHoverSettings({
                mode: 'x-axis',
            });
            vm.lineChartSettings.options = ng2LegendCursorService.setHoverState(vm.lineChartSettings.options);
            return vm.lineChartSettings.options;
        }

        /**
         * @memberOf QASpellcheckLineChartService
         * @description The colors for the QA spellcehck line chart
         */
        function getColors () {
            vm.lineChartSettings.addColors(['#fcb785', '#9ac6df']);
            return vm.lineChartSettings.colors;
        }

        /**
         * @memberOf QASpellcheckLineChartService
         * @description The series for the QA spellcehck line chart
         */
        function getSeries () {
            vm.lineChartSettings.addSeries([
                gettextCatalog.getString('Unique misspellings'),
                gettextCatalog.getString('Potential misspellings'),
            ]);
            return vm.lineChartSettings.series;
        }

        /**
         * @memberOf QASpellcheckLineChartService
         * @description The override object for the QA spellcehck line chart
         */
        function getOverride () {
            vm.lineChartSettings.addOverrides([
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    fill: false,
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    fill: false,
                },
            ]);
            return vm.lineChartSettings.override;
        }

        /**
         * @memberOf QASpellcheckLineChartService
         * @description The labels for the QA spellcehck line chart
         */
        function getLabels (crawls) {
            return crawls.map(function (crawl) {
                return ng2DateTimeService.format(crawl.date, 'MMM DD');
            });
        }

        /**
         * @memberOf QASpellcheckLineChartService
         * @description The data object for the QA spellcehck line chart
         */
        function getData (crawls) {
            return [
                crawls.map(function (crawl) {
                    return crawl.spelling_errors_confirmed_count || 0;
                }),
                crawls.map(function (crawl) {
                    return crawl.spelling_errors_potential_count || 0;
                }),
            ];
        }
    }
})();
