import { DataPrivacyViolationType } from '@monsido/ng2/modules/data-privacy/interfaces/data-privacy-violation.type';

// eslint-disable-next-line
export interface DataPrivacyViolationModel extends DataPrivacyViolationType {}

export class DataPrivacyViolationModel {

    constructor (data: DataPrivacyViolationType) {
        Object.assign(this, data);
    }

}
