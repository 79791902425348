import { Injectable } from '@angular/core';
import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { ChartsDataHelper } from '../../helpers/charts/charts-data-helper';
import { ChartFactory } from './base-chart/chart-factory';

@Injectable({
    providedIn: 'root',
})
export class SEOLineChartFactory {
    constructor (
        private translateService: TranslateService,
    ) {}

    createSEOLineChart (): ChartFactory {
        const seoLineChartService = new ChartFactory(new LineChart());
        seoLineChartService.setOptions([
            this.translateService.getString('Issues'),
            this.translateService.getString('Pages'),
        ]);
        seoLineChartService.setColors(
            ['#A94442', '#F0AD4E', '#31708F', '#727980', '#ff9d4e', '#a9dad6'],
        );
        seoLineChartService.setSeries([
            this.translateService.getString('High'),
            this.translateService.getString('Medium'),
            this.translateService.getString('Low'),
            this.translateService.getString('Technical'),
            this.translateService.getString('Pages with issues'),
            this.translateService.getString('Scanned pages'),
        ]);
        seoLineChartService.setOverrides([4, 2]);
        seoLineChartService.setData(
            [
                'seo_errors_alerts_count',
                'seo_errors_warnings_count',
                'seo_errors_infos_count',
                'seo_errors_technical_count',
                'pages_with_seo_errors_count',
                'page_count',
            ],
            new ChartsDataHelper('time'),
        );

        return seoLineChartService;
    }
}
