import { Injectable } from '@angular/core';
import { LIKELIHOODS } from '../constants/data-privacy-likelihood.constant';
import { DataPrivacyViolationType } from '../interfaces/data-privacy-violation.type';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Injectable()
export class DataPrivacyLikelihoodService {
    constructor (private translateService: TranslateService) {}

    getViolationLikelihood (violation: DataPrivacyViolationType): string {
        if (violation.likelihood && LIKELIHOODS[violation.likelihood]) {
            return this.translateService.getString(LIKELIHOODS[violation.likelihood]);
        }

        return this.translateService.getString('Likelihood unspecified');
    }
}
