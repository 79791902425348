import { ChartDataSets, ChartHoverSettings, ChartLegendSettings, ChartModel, ChartOptions, ChartScalesSettings, ChartTooltipCallbacks, ChartTooltipsSettings } from '@client/app/blocks/service/charts/base-chart/chart-factory';
import { PositionType } from 'chart.js';
import { merge } from 'lodash';

export class BaseChart {
    colors: string[];
    override: ChartDataSets[];
    options: ChartOptions;

    constructor (attributes?: Partial<ChartModel>) {
        Object.assign(this, attributes);
        this.options = {
            layout: {
                padding: {
                    bottom: 3,
                    top: 3,
                },
            },
        };
        this.colors = [];
        this.override = [];
    }

    setHoverCursor (): void {
        this.options.onHover = (e: MouseEvent, el: HTMLElement[]): void => {
            const target = e.currentTarget as HTMLElement;
            if (!target) {
                return;
            }
            const section = el[0];
            const currentStyle = target.style;
            currentStyle.cursor = section ? 'pointer' : 'normal';
        };
    }

    setTitle (title: string): void {
        this.options.title = { display: true, text: title };
    }

    setScalesSettings (scales: ChartScalesSettings): void {
        this.assertObject(scales);
        this.options.scales = scales;
    }

    setTooltipSettings (tooltip: ChartTooltipsSettings): void {
        this.assertObject(tooltip);
        this.options.tooltips = tooltip;
    }

    setTooltipCallbacks (callbacks: ChartTooltipCallbacks): void {
        this.assertObject(callbacks);
        if (!this.isObject(this.options.tooltips)) {
            this.setTooltipSettings({});
        }

        if (this.options.tooltips) {
            this.options.tooltips.callbacks = callbacks;
        }
    }

    setTooltipCustom (tooltips: ChartTooltipsSettings): void {
        this.assertObject(tooltips);
        if (!this.isObject(this.options.tooltips)) {
            this.setTooltipSettings({});
        }
        this.options.tooltips = merge(this.options.tooltips, tooltips);
        if (!this.options.tooltips) {
            this.options.tooltips = {};
        }
        this.options.tooltips.enabled = false;
        this.options.tooltips.custom = function (tooltipModel): void {
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            // Set Text
            if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(b => b.lines);

                let innerHtml = '<thead>';

                titleLines.forEach( (title) => {
                    innerHtml += '<tr><th style="padding-bottom: 3px;">' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';
                bodyLines.forEach( (body, i) => {
                    const colors = tooltipModel.labelColors[i];
                    let style = 'background:' + colors.backgroundColor;
                    style += '; border-color:' + colors.borderColor;
                    style +=
                            '; border-width: 1px; border-style: solid; width: 12px; height: 12px; position: absolute; top: 0; left: 0; display: inline-block;pointer-events: none;';
                    const span = '<span style="' + style + '"></span>';
                    const bgSpan =
                            '<span style="position: absolute; top: 0; left: 0; width: 12px; height: 12px; background: white; display: inline-block; z-index: -1;"></span>';
                    innerHtml +=
                            '<tr><td style="position: relative; padding-left: 18px;">' +
                            bgSpan +
                            span +
                            body +
                            '</td></tr>';
                });
                innerHtml += '</tbody>';
                const tableRoot = tooltipEl.querySelector('table');
                if (tableRoot) {
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                const position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.backgroundColor = '#000';
                tooltipEl.style.color = '#fff';
                tooltipEl.style.opacity = '0.8';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.borderRadius = '5px';
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.zIndex = '99999';
            }
        };
    }

    setLegendSettings (display: boolean, position: PositionType): void {
        this.assertBoolean(display);
        this.assertString(position);
        if (!this.isObject(this.options.legend)) {
            this.options.legend = {};
        }

        this.options.legend.display = display;
        this.options.legend.position = position;
    }

    setHoverSettings (hover: ChartHoverSettings): void {
        this.assertObject(hover);
        if (!this.isObject(this.options.hover)) {
            this.options.hover = {};
        }

        this.options.hover = merge(this.options.hover, hover);
    }

    setLegendSettingsOverride (legend: ChartLegendSettings): void {
        this.assertObject(legend);
        if (!this.isObject(this.options.legend)) {
            this.options.legend = {};
        }

        this.options.legend = merge(this.options.legend, legend);
    }

    addColor (color: string | string[]): void {
        if (this.isArray(color)) {
            this.addColors(color);
        } else {
            this.colors.push(color);
        }
    }

    addColors (colors: string[]): void {
        this.assertArray(colors);
        this.colors = this.colors.concat(colors);
    }

    setOverride (override: ChartDataSets[]): void {
        this.assertArray(override);
        this.override = override;
    }

    addOverride (override: ChartDataSets | ChartDataSets[]): void {
        if (this.isArray(override)) {
            this.addOverrides(override);
        } else {
            this.assertObject(override);
            this.override.push(override);
        }
    }

    addOverrides (overrides: ChartDataSets[]): void {
        this.assertArray(overrides);
        this.override = this.override.concat(overrides);
    }

    private isArray (value: unknown): value is unknown[] {
        return Array.isArray(value) || (value instanceof Array);
    }

    private assertArray (value: unknown): value is unknown[] {
        if (!this.isArray(value)) {
            throw new Error('Input must be of type array');
        }
        return true;
    }

    private isObject (value: unknown): value is object {
        return value !== null && typeof value === 'object';
    }

    private assertObject (value: unknown): value is object {
        if (!this.isObject(value)) {
            throw new Error('Input must be of type object');
        }
        return true;
    }

    private assertString (value: unknown): value is string {
        const isString = typeof value === 'string' ? value.trim() : value;
        if (!isString) {
            throw new Error('Input must be of type string');
        }
        return true;
    }

    private assertBoolean (value: unknown): value is boolean {
        if (typeof value !== 'boolean') {
            throw new Error('Input must be of type boolean');
        }
        return true;
    }


    static hideTooltip (): void {
        const tooltipEl: HTMLElement | null = document.querySelector('#chartjs-tooltip');
        if (tooltipEl) {
            tooltipEl.style.top = '-9999px';
        }
    }

    static setupListeners (): void {
        document.addEventListener('keyup', this.hideTooltip);
    }

    static teardownListeners (): void {
        document.removeEventListener('keyup', this.hideTooltip);
    }

}
