import { Injectable } from '@angular/core';
import { ApiService } from '../../api-service/api.service';

type PagePerformanceReportParamsType = {
    page_id: number;
    profile_id: number;
}
export type PagePerformanceReportAudit = {
    id: string,
    description: string,
    scoreDisplayMode: string,
    score: number | null,
    title: string
    displayValue?: string,
    explanation?: string,
    numericValue?: number,
    numericUnit?: string,
    details?: {
        headings?: {
            key: string,
            label: string,
            subItemsHeading?: {
                key: string,
                valueType: string,
                label: string
            }[],
            items: {
                failureType: string,
                protocolReason: string,
                reason: string,
                subItems: {
                    type: string,
                    items: {
                        [x:string]: string
                    }[]
                }[]
            }[],
            type: string
        }[],
        item?: {
            [x:string]: string | number | boolean | null
        }[],
        type: string,
    },
    metrics?: string[],
};
type PagePerformanceReportCategoryType = {
    auditRef: {
        acronym: string,
        group: string,
        id: string,
        relevantAudits: string[],
    }[],
    id: string,
    score: number,
    supportedModes: string[],
    title: string,
    weight: number
};
type PagePerformanceReportGroupType = {
    description?: string,
    title: string
};
export type PagePerformanceReportType = {
    audits: {
        [x:string]: PagePerformanceReportAudit
    },
    categories: {
        [x:string]: PagePerformanceReportCategoryType
    },
    categoryGroups: {
        [x:string]: PagePerformanceReportGroupType
    },
    configSettings: unknown,
    environment: {
        benchmarkIndex: number,
        credit: unknown,
        hostUserAgent: string,
        networkUserAgent: string
    },
    fetchTime: string,
    finalUrl: string,
    i18n: {
        icuMessagePaths: {
            [x:string]: string[] | { path: string, values: { itemCount: number }}[]
        },
        rendererFormattedStrings: {
            [x:string]: string,
        }
    },
    lighthouseVersion: string,
    runWarnings: unknown[],
    timing: {
        entries: {
            startTime: number, name: string, duration: number, entryType: string
        }[],
        total: number,
    },
    userAgent: string,
    version: string
};

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceReportRepoService {

    constructor (private apiService: ApiService) { }

    getAll (params: PagePerformanceReportParamsType): Promise<PagePerformanceReportType> {
        return this.apiService.get('performance/reports', params);
    }
}
