import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionPagePerformance', {
        templateUrl: 'app/components/pagedetails/details/sections/page-performance/page-performance.html',
        controller: SectionPagePerformanceController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            active: '<',
        },
    });

    SectionPagePerformanceController.$inject = [
        'ng2PagePerformancePageDetailsService',
        'ng2PagePerformanceReportRepoService',
        'ng2PagePerformanceRescanRepoService',
        'PagePerformanceScanService',
        'ng2MonEventsService',
        '$interval',
        'ng2DateTimeService',
        'gettextCatalog',
        'ng2SessionService',
        'PagePerformanceAssginProfileService',
    ];

    /* @ngInject */
    function SectionPagePerformanceController (
        ng2PagePerformancePageDetailsService,
        ng2PagePerformanceReportRepoService,
        ng2PagePerformanceRescanRepoService,
        PagePerformanceScanService,
        ng2MonEventsService,
        $interval,
        ng2DateTimeService,
        gettextCatalog,
        ng2SessionService,
        PagePerformanceAssginProfileService,
    ) {
        const vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.goToActive = goToActive;
        vm.performanceRescan = performanceRescan;
        vm.getSubHeader = getSubHeader;
        vm.onResultChange = onResultChange;
        vm.loadingReport = loadingReport;
        vm.hasProfile = hasProfile;
        vm.assignProfile = assignProfile;
        vm.canUseOnDemandScan = canUseOnDemandScan;

        function activate () {
            vm.scanStatusSubscription = null;
            vm.active = selectSection();
            vm.profile = null;
            vm.noData = false;
            vm.canRescan = false;
            vm.rescanning = false;
            vm.ProfileSetupFinished = false;
            setupListeners();
        }

        function onDestroy () {
            disposeScanStatus();
            ng2MonEventsService.remove(vm.onSelectProfileID);
            ng2MonEventsService.remove(vm.afterProfileSetupID);
            ng2MonEventsService.remove(vm.beforeProfileSetupID);
        }

        function goToActive (active) {
            vm.active = active;
        }

        function onResultChange (lastCheck) {
            vm.lastCheck = lastCheck;
        }

        function getSubHeader () {
            let lastCheckTime;
            if (vm.lastCheck) {
                lastCheckTime = ng2DateTimeService.formatBrowserLocalized(vm.lastCheck.scanned_at,'LL LTS');
            } else {
                lastCheckTime = gettextCatalog.getString('None');
            }
            return gettextCatalog.getString('Last check: {{lastCheckTime}}', {
                lastCheckTime: lastCheckTime,
            });
        }

        function performanceRescan () {
            PagePerformanceScanService.performanceRescan(vm.page, vm.profile).then(() => {
                vm.canRescan = false;
                vm.rescanning = true;
                disposeScanStatus();
                runRescanInterval();
            }, angular.noop);
        }

        function hasProfile () {
            return !vm.ProfileSetupFinished || vm.profile !== null;
        }

        function loadingReport () {
            return hasProfile() && ng2PagePerformancePageDetailsService.getLoadingReportStatus();
        }

        function assignProfile () {
            PagePerformanceAssginProfileService.assignProfile(vm.page);
        }

        function canUseOnDemandScan () {
            return Boolean(ng2SessionService.customer !== null && ng2SessionService.customer.plan_traits.performance_tracking_on_demand_scan);
        }

        // PROTECTED

        function setupListeners () {
            vm.onSelectProfileID = ng2MonEventsService.addListener('pagePerformanceSelectProfile', (profile) => {
                if (profile) {
                    vm.canRescan = false;
                    disposeScanStatus();
                    runRescanInterval();
                    vm.profile = profile;
                    getReport();
                }
            });

            vm.afterProfileSetupID = ng2MonEventsService.addListener('pagePerformanceAfterSelectProfile', () => {
                vm.ProfileSetupFinished = true;
            });

            vm.beforeProfileSetupID = ng2MonEventsService.addListener('pagePerformanceBeforeSelectProfile', () => {
                vm.ProfileSetupFinished = false;
            });
        }

        function getReport () {
            vm.noData = false;
            const params = {
                page_id: vm.page.id,
                profile_id: vm.profile.id,
            };
            ng2MonEventsService.run('pagePerformancePDBeforeSetReport');
            ng2PagePerformanceReportRepoService.getAll(params)
                .then(
                    (report) => {
                        vm.report = report;
                        ng2MonEventsService.run('pagePerformancePDOnSetReport', vm.report);
                        ng2MonEventsService.run('pagePerformanceUpdateAssignedProfile', vm.page, vm.profile);
                    },
                    () => {
                        vm.report = null;
                        ng2MonEventsService.run('pagePerformancePDOnSetReport', vm.report);
                        vm.noData = true;
                    },
                )
                .finally(() => {
                    ng2MonEventsService.run('pagePerformancePDAfterSetReport');
                });
        }

        function selectSection () {
            const sections = [];
            const index = sections.indexOf(vm.active);
            if (index === -1) {
                return 'dashboard';
            }
            return sections[index];
        }

        function runRescanInterval () {
            vm.scanStatusSubscription = $interval(() => {
                getScanCollection().then(
                    (scans) => {
                        /** It's safe to attempt a rescan if
                         * No Scan records were found
                         * Last scan record is finished
                         * The last attempted scan is more than an hour old
                         */
                        if (scans.length === 0) {
                            vm.canRescan = true;
                            vm.rescanning = false;
                            return;
                        }

                        // We should only allow a new report if a new scan has finished up
                        const isFetchTimeBefore = ng2DateTimeService.isBefore(
                            ng2DateTimeService.toUtcDate(vm.report.fetchTime),
                            scans[0].created_at,
                        );
                        const scanContainsNewReport = !vm.report || isFetchTimeBefore;

                        const isOneHourOld = ng2DateTimeService.isAfter(
                            ng2DateTimeService.substractDuration(undefined, 1, 'hours'),
                            scans[0].created_at,
                        );
                        vm.canRescan = Boolean(
                            scans[0].status === 'finished' || isOneHourOld,
                        );

                        // Dispose of observable if vm.canRescan is true
                        if (vm.canRescan) {
                            vm.rescanning = false;
                            disposeScanStatus();
                        }

                        if (vm.canRescan && scanContainsNewReport) {
                            getReport();
                        }
                    },
                    function () {
                        // If the endpoint errored, we are gonna assume it is safe for the client to attempt a rescan
                        vm.canRescan = true;
                        vm.rescanning = false;
                        disposeScanStatus();
                    },
                );
            }, 15000);
        }

        function disposeScanStatus () {
            if (vm.scanStatusSubscription !== null) {
                $interval.cancel(vm.scanStatusSubscription);
                vm.scanStatusSubscription = null;
            }
        }

        function getScanCollection () {
            const params = {
                page_id: vm.page.id,
                profile_id: vm.profile.id,
            };

            return ng2PagePerformanceRescanRepoService.get(params, contextTokens.NO_GLOBAL);
        }
    }
})();
