import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { UIRouter, UrlService } from '@uirouter/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './angularjs-app';
import 'app/downgraded-ng2/constants.downgrade.js';
import 'app/downgraded-ng2/services.downgrade.js';
import '../client/app/downgraded-ng2/test-upgrade.component.downgrade.js';
import { OverlayService } from '@monsido/services/overlay-service/overlay.service';
import { OauthService } from '@monsido/oauth/oauth.service';
import { BaseChart } from './app/modules/charts/models/chart';

if (environment.production) {
    enableProdMode();
}
platformBrowserDynamic()
    .bootstrapModule(AppModule, [])
    .then((platformRef) => {
        // Intialize the Angular Module
        // get() the UIRouter instance from DI to initialize the router
        const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
        const overlayService = platformRef.injector.get(OverlayService);
        const oauthService = platformRef.injector.get(OauthService);

        let previousParams = '';
        let overlayIsOpen = false;

        const processRouteChangeForOverlay = (): void => {
            const queryParams = urlService.search();
            const paramsAsString = JSON.stringify(queryParams);

            // TODO: add a handler for multiple overlays here
            if (queryParams.issueOverlay) {
                if (paramsAsString !== previousParams) {
                    if (previousParams.includes('issueOverlay=true')) {
                        delete queryParams.issueOverlay;
                    }
                    overlayIsOpen = true;
                    overlayService.openOverlay(queryParams);
                }
            } else if (overlayIsOpen) {
                overlayService.closeAllDialogs();
                overlayIsOpen = false;
            }

            previousParams = paramsAsString;
        };

        // Instruct UIRouter to listen to URL changes
        function startUIRouter (): void {
            urlService.listen();
            urlService.sync();
            urlService.onChange(() => {
                processRouteChangeForOverlay();
            });

            oauthService
                .processStateParam(urlService.search().state)
                .onSuccess(() => {
                    processRouteChangeForOverlay();
                });
        }

        platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);

        BaseChart.setupListeners();
        window.addEventListener('beforeunload', (): void => {
            BaseChart.teardownListeners();
        });

    })
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
