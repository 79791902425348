(function () {
    'use strict';
    angular.module('modules.page_fix.components').service('PageFixAccessibilityChecksService', PageFixAccessibilityChecksService);

    PageFixAccessibilityChecksService.$inject = [
        'ng2AccessibilityAutofixService',
        'ng2TextSearchService',
        'gettextCatalog',
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        '$deepmerge',
        'PageFixAccessibilityDifficultyService',
        'ng2PageFixAccessibilityConfigService',
        'PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY',
        'PAGE_FIX_ACCESSIBILITY_CHECK_TAB',
        '$filter',
        'ng2SessionService',
    ];

    /* @ngInject*/
    function PageFixAccessibilityChecksService (
        ng2AccessibilityAutofixService,
        ng2TextSearchService,
        gettextCatalog,
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        $deepmerge,
        PageFixAccessibilityDifficultyService,
        ng2PageFixAccessibilityConfigService,
        PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY,
        PAGE_FIX_ACCESSIBILITY_CHECK_TAB,
        $filter,
        ng2SessionService,
    ) {
        var vm = this;
        vm.accessibilityCheckConfig = $deepmerge({}, PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG);

        function init () {
            return {
                filterAccessibilityChecks: filterAccessibilityChecks,
                getAccessibilityCheckConfig: getAccessibilityCheckConfig,
                sortAccessibilityChecks: sortAccessibilityChecks,
                getDifficulty: getDifficulty,
                getDescription: getDescription,
                getImage: getImage,
                isHardDifficulty: isHardDifficulty,
                getLoadingScriptUrl: getLoadingScriptUrl,
                getTabIcon: getTabIcon,
                getTabTranslation: getTabTranslation,
            };
        }

        return init();

        // ///////////////

        function getAccessibilityCheckConfig () {
            return vm.accessibilityCheckConfig;
        }

        // TODO: remove this function when reinstating the split sections https://zube.io/monsido/monsido/c/21815
        function getTabIcon (accessibilityCheck) {
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[accessibilityCheck.id];
            switch (config.work_types[0]) {
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE:
                    return $filter('monIcons')(['PAGE_FIX', 'WEBSITE_STRUCTURE']);
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN:
                    return $filter('monIcons')(['PAGE_FIX', 'DESIGN']);
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS:
                    return $filter('monIcons')(['PAGE_FIX', 'LINKS']);
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA:
                    return $filter('monIcons')(['PAGE_FIX', 'MEDIA']);
                default:
                    return 'fas fa-question'; // If this is shown, you done goofed.
            }
        }

        // TODO: remove this function when reinstating the split sections https://zube.io/monsido/monsido/c/21815
        function getTabTranslation (accessibilityCheck) {
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[accessibilityCheck.id];
            switch (config.work_types[0]) {
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE:
                    return gettextCatalog.getString('Website Structure');
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN:
                    return gettextCatalog.getString('Design');
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS:
                    return gettextCatalog.getString('Links');
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA:
                    return gettextCatalog.getString('Media');
                default:
                    return gettextCatalog.getString('Unknown'); // If this is shown, you done goofed.
            }
        }

        // eslint-disable-next-line no-unused-vars
        function filterAccessibilityChecks (accessibilityChecks, search, tab) {
            return accessibilityChecks.filter(function (accessibilityCheck) {
                return (
                    ng2AccessibilityAutofixService.useCheckId(accessibilityCheck.id) &&
                    (!search || ng2TextSearchService.fuzzySearch(search, accessibilityCheck.nameEscaped)) &&
                    (!tab || hasTab(accessibilityCheck, tab))
                );
            });
        }

        /**
         * @param checks - Accessibility Checks list
         * @param pageFixChecks - PageFix checks list
         * @param sortBy - options are "name" or "status"
         * @param sortDirection - options are "asc" or "desc"
         */
        function sortAccessibilityChecks (checks, pageFixChecks, sortBy, sortDirection) {
            if (!sortBy) {
                return checks;
            }
            return checks.sort(function (a, b) {
                if (sortBy === 'status') {
                    var pageFixA = Boolean(pageFixChecks[a.id]);
                    var pageFixB = Boolean(pageFixChecks[b.id]);
                    if (pageFixA !== pageFixB) {
                        if (sortDirection === 'asc') {
                            return pageFixA ? 1 : -1;
                        } else {
                            return pageFixA ? -1 : 1;
                        }
                    }
                } else if (sortBy === 'difficulty') {
                    var difficultyA = getDifficulty(a);
                    var difficultyB = getDifficulty(b);
                    if (difficultyA.weight !== difficultyB.weight) {
                        if (sortDirection === 'asc') {
                            return difficultyA.weight > difficultyB.weight ? 1 : -1;
                        } else {
                            return difficultyA.weight > difficultyB.weight ? -1 : 1;
                        }
                    }
                } else {
                    var lowercaseA = a.nameEscaped.toLowerCase();
                    var lowercaseB = b.nameEscaped.toLowerCase();
                    if (lowercaseA !== lowercaseB) {
                        if (sortDirection === 'asc') {
                            return lowercaseA > lowercaseB ? 1 : -1;
                        } else {
                            return lowercaseA > lowercaseB ? -1 : 1;
                        }
                    }
                }

                return 0;
            });
        }

        function getDifficulty (accessibilityCheck) {
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[accessibilityCheck.id];
            return PageFixAccessibilityDifficultyService.getDifficulty(config.difficulty);
        }

        function isHardDifficulty (check) {
            var difficulty = getDifficulty(check);
            return PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.HARD === difficulty.value;
        }

        function getDescription (check) {
            return ng2PageFixAccessibilityConfigService.getDescription(check);
        }

        function getImage (accessibilityCheck) {
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[accessibilityCheck.id];
            return config.imagePath;
        }

        function getLoadingScriptUrl () {
            if (ng2SessionService.accessibilityMode) {
                return '/images/page-fix/accessibility-mode-script-loading.gif';
            }
            return '/images/page-fix/script-loading.gif';
        }

        // PROTECTED

        // eslint-disable-next-line no-unused-vars
        function hasTab (accessibilityCheck, tab) {
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[accessibilityCheck.id];
            return config.work_types.indexOf(tab) > -1;
        }
    }
})();
