import { DEFAULT_CHART_SETTINGS } from 'deprecate/frontend-components/src/js/components/charts/Chart.constant';
import { CrawlHistory } from '@monsido/modules/models/api/interfaces/domain.interface';
import {
    ChartTooltipOptions as ChartJsTooltipOptions,
    ChartTooltipCallback as ChartJsTooltipCallback,
    ChartHoverOptions as ChartJsHoverOptions,
    ChartLegendOptions as ChartJsLegendOptions,
    ChartXAxe as ChartJsChartXAxe,
    ChartYAxe as ChartJsChartYAxe,
    ChartOptions as ChartJsOptions,
    ChartDataSets as ChartJsDataSets,
} from 'chart.js';
import { LineChart } from '@client/app/modules/charts/models/charts/line';
import { ChartsDataHelper } from '@client/app/blocks/helpers/charts/charts-data-helper';


export type ChartScalesSettings = {
    xAxes?: ChartJsChartXAxe[];
    yAxes?: ChartJsChartYAxe[];
};

export type ChartTooltipsSettings = ChartJsTooltipOptions;
export type ChartTooltipCallbacks = ChartJsTooltipCallback;
export type ChartHoverSettings = ChartJsHoverOptions;
export type ChartLegendSettings = ChartJsLegendOptions;
export type ChartOptions = ChartJsOptions;
export type ChartDataSets = ChartJsDataSets;

export interface ChartModel {
    options: ChartOptions;
    colors: string[];
    override: ChartDataSets;

    setHoverCursor: () => void;
    setTitle: (title: string) => void;
    setScalesSettings: (scales: ChartScalesSettings) => void;
    setTooltipSettings: (tooltips: ChartTooltipsSettings) => void;
    setTooltipCallbacks: (callbacks: ChartTooltipCallbacks) => void;
    setTooltipCustom: (tooltips: ChartTooltipsSettings) => void;
    setLegendSettings: (display: boolean, position: string) => void;
    setHoverSettings: (hover: ChartHoverSettings) => void;
    setLegendSettingsOverride: (legend: ChartLegendSettings) => void;
    addColor: (color: string | string[]) => void;
    addColors: (colors: string[]) => void;
    setOverride: (override: unknown) => void;
    addOverride: (override: unknown | unknown[]) => void;
    addOverrides: (override: unknown[]) => void;
    checkArray: (input: unknown[]) => void;
    checkObject: (input: unknown) => void;
    hideTooltip: () => void;
}

export interface DoughnutChartModel extends ChartModel {
    setCutOutPercentage: (percentage: number) => void;
    setCenterText: (percentage: number | string, totalLabel: string, subLabel: string) => void;
    setPercentageFontSize: (size: number) => void;
    setPercentageFontWeight: (size: number) => void;
    setTotalFontSize: (size: number) => void;
    setTotalFontWeight: (size: number) => void;
    setSubFontSize: (size: number) => void;
    setSubFontWeight: (size: number) => void;
}

export type ChartDataEntry = number | { x: string, y: number }
type DataSeriesType = string | string[]

export class ChartFactory {
    data: ChartDataEntry[][] = [];
    dataSeries: DataSeriesType[] = [];
    helper: ChartsDataHelper | null = null;

    constructor (private chart: ChartModel | LineChart | DoughnutChartModel) {}

    setHoverState (options: ChartOptions): ChartOptions {
        const newOptions = options || {
            legend: {},
        };

        if (newOptions.legend) {
            newOptions.legend.onHover = (event: MouseEvent): void => {
                const target = event.target as HTMLElement;
                if (target) {
                    target.style.cursor = 'pointer';
                }
            };

            newOptions.legend.onLeave = (event: MouseEvent): void => {
                const target = event.target as HTMLElement;
                if (target) {
                    target.style.cursor = 'default';
                }
            };
        }
        return newOptions;
    }

    setOptions (labelStrings: string[]): void {
        this.chart.setTooltipSettings({
            ...DEFAULT_CHART_SETTINGS.TOOLTIP_SETTINGS,
        } as ChartTooltipsSettings);

        this.chart.setLegendSettingsOverride({
            ...DEFAULT_CHART_SETTINGS.LEGEND_SETTINGS_OVERRIDE,
        } as ChartLegendSettings);

        const setting = {
            ...DEFAULT_CHART_SETTINGS.SCALE_SETTINGS,
        } as unknown as ChartScalesSettings;

        if (setting.yAxes?.[0]?.scaleLabel) {
            setting.yAxes[0].scaleLabel.labelString = labelStrings[0];
        }
        if (setting.yAxes?.[1]?.scaleLabel) {
            setting.yAxes[1].scaleLabel.labelString = labelStrings[1];
        }

        this.chart.setScalesSettings(setting);
        this.chart.options = this.setHoverState(this.chart.options);
    }

    setColors (colors: string[]): void {
        this.chart.addColors(colors);
    }

    setSeries (series: string[]): void {
        if ('addSeries' in this.chart) {
            this.chart.addSeries(series);
        }
    }

    setOverrides (overrides: number[]): void {
        this.chart.addOverrides([
            ...Array(overrides[0]).fill(DEFAULT_CHART_SETTINGS.OVERRIDES.y0),
            ...Array(overrides[1]).fill(DEFAULT_CHART_SETTINGS.OVERRIDES.y1),
        ]);
    }

    getOption (): unknown {
        return this.chart.options;
    }

    getColors (): unknown {
        return this.chart.colors;
    }

    getSeries (): string[] {
        if ('series' in this.chart) {
            return this.chart.series;
        }
        return [];
    }

    getOverride (): ChartDataSets | ChartDataSets[] {
        return this.chart.override;
    }

    getLabels (crawls: CrawlHistory[]): number[] {
        crawls = crawls || [];
        return crawls.map((crawl) => {
            return new Date(crawl.date).getTime();
        });
    }
    setData (dataSeries: DataSeriesType[], helper: ChartsDataHelper): void {
        this.dataSeries = dataSeries;
        this.helper = helper;
    }

    getData (crawls: CrawlHistory[]): ChartDataEntry[][] {
        const data: ChartDataEntry[][] = [];
        if (!this.helper) {
            throw new Error('Missing ChartsDataHelper');
        }

        for (const dataSeries of this.dataSeries) {
            data.push(crawls.map((crawl) => {
                return this.helper?.setData(crawl, dataSeries) as ChartDataEntry;
            }));
        }
        return data;
    }
}
